.stack {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  &__child {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;
  }
}
